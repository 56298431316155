<template>
  <div>
    <el-affix>
      <nav-bar></nav-bar>
    </el-affix>
    <tool-box></tool-box>

    <!-- 轮播图 -->
    <div class="swiper-wrap">
      <div class="swiper">
        <swiper :slides-per-view="1" :space-between="0" navigation :autoplay="{ delay: 3000, disableOnInteraction: false }" loop :pagination="{ clickable: true }">
          <swiper-slide @click="adInfo(item)" v-for="(item, index) in bannerList" :key="index">
            <li :title="item.title"
              :style="{
                backgroundImage: 'url(\'' + item.thumb + '\')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                height: '350px',
                width: screenWidth + 'px',
                cursor: 'pointer',
              }"
            ></li>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <!-- 专题列表 -->
    <div class="special">
      <div class="special-list container">
        <div :class="'special-list-item' + (currentSpecial.id == item.id ? ' active' : '')"
          @click="getComboList(item)"
          v-for="(item, index) in specialList" :key="index">{{ item.title }}</div>
      </div>
    </div>

    <!-- 二级专题列表及套餐 -->
    <div class="special-children container">
      <div class="special-children-item" v-for="item in currentSpecial.children" :key="item.loading">
        <div class="item-title" title="点击查看专题详情" @click="this.$router.push('/special/' + item.id)">{{ item.title }}</div>
        <div class="item-combo" v-loading="item.loading">
          <div class="item-combo-item" @click="goCourseDetail(combo)" v-for="combo in item.comboList" :key="combo.id">
            <div class="combo-item-img"><img :src="combo.thumb" alt=""></div>
            <div class="combo-item-title" :title="combo.title">{{ combo.title }}</div>
            <div class="combo-item-service">
              <span v-for="item in combo.service.split('/')" :key="item">{{ item }}</span>
            </div>
            <div class="combo-item-bottom">
              <div class="price">￥{{ combo.price }}<span>起</span></div>
              <div class="num">{{ combo.num ? combo.num : 0 }}人已报名</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay} from "swiper"
import { Swiper, SwiperSlide } from "swiper/vue"
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay])

import NavBar from "@/components/page/NavBar"
import ToolBox from "@/components/toolbox"
import WebFooter from "@/components/page/WebFooter"

import { getBanner, getSpecial, getCombo } from "@/api/index3"

export default {
  components: {
    Swiper,
    SwiperSlide,
    NavBar,
    ToolBox,
    WebFooter
  },
  data() {
    return {
      // 轮播图
      bannerList: [],
      // 专题
      specialList: [],
      // 当前专题
      currentSpecial: {},

    }
  },
  created() {
    // 获取轮播图
    getBanner().then(res => {
      if (res.code == 1) {
        this.bannerList = res.data;
      }
    })
    // 获取专题
    getSpecial().then(res => {
      if (res.code == 1) {
        this.specialList = res.data
        this.getComboList(this.specialList[0])
      }
    })
  },
  methods: {
    // 轮播图广告跳转
    adInfo(list) {
      if (list.type == 1) {// 海报
       
      }
      if (list.type == 2) {// 课程列表
        let idArr = list.urlId.split(",")
        this.$router.push("/course/" + idArr[0] + "/" + idArr[1])
      }
    },
    // 获取专题下各二级专题的套餐
    getComboList(special) {
      this.currentSpecial = special
      this.currentSpecial.children.forEach(item => {
        item.loading = true
        getCombo({spec_id: item.id}).then(res => {
          if (res.code == 1) {
            let comboList = []
            res.data.forEach(item => {
              if (item.status == 1 && item.combo && item.combo.length > 0) {
                item.thumb = item.combo[0].thumb
                item.price = item.combo[0].sale_price
                item.combo.forEach(combo => {
                  if (combo.sale_price < item.price) {
                    item.price = combo.sale_price
                  }
                })
                comboList.push(item)
              }
            })
            item.comboList = comboList
            item.loading = false
          }
        })
      })
    },
    // 跳转课程详情
    goCourseDetail(course){
      this.$router.push('/coursedetail/' + course.spec_id + '/' + course.id + '/' + course.combo[0].id)
    }

  },
};
</script>

<style scoped lang="scss">
// 轮播图 begin
.swiper-wrap {
  width: 100%;
  overflow: hidden;
  position: relative;
}
// 轮播图 end

// 专题列表 begin
.special {
  border-bottom: 1px solid #e5e5e5;
  .special-list {
    display: flex;
    justify-content: center;
    .special-list-item {
      color: #666;
      font-size: 20px;
      padding: 15px;
      cursor: pointer;
    }
    .special-list-item:hover {
      color: $theme-color;
    }
    .special-list-item.active {
      color: $theme-color;
    }
  }
}
// 专题列表 end

// 二级专题列表 begin
.special-children {
  margin: 50px auto;
  .special-children-item {
    .item-title {
      height: 90px;
      line-height: 90px;
      font-size: 30px;
      text-align: center;
      cursor: pointer;
    }
    .item-title:hover {
      color: $theme-color
    }
    .item-combo {
      min-height: 280px;
      display: flex;
      flex-wrap: wrap;
      .item-combo-item {
        width: 270px;
        height: 212px;
        padding: 10px;
        margin: 10px 5px;
        background-color: #fff;
        cursor: pointer;
        .combo-item-img {
          width: 100%;
          height: 150px;
        }
        .combo-item-title {
          width: 100%;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .combo-item-service {
          color: #999;
          font-size: 12px;
          margin-top: 2px;
          span {
            border-left: 1px solid #999;
            padding: 0 6px;
          }
          span:first-child {
            border: none;
            padding-left: 0;
          }
        }
        .combo-item-bottom {
          margin-top: 5px;
          display: flex;
          align-items: flex-end;
          .price {
            color: $price-color;
            font-size: 16px;
            span {
              color: #999;
              font-size: 12px;
              margin-left: 2px;
            }
          }
          .num {
            color: #999;
            font-size: 12px;
            margin-left: 10px;
          }
        }
      }
      .item-combo-item:hover {
        background-color: #fff;
        box-shadow: 0 0 1px 1px $theme-color;
        .combo-item-title {
          color: $theme-color;
        }
      }
    }
  }
}
// 二级专题列表 end
</style>